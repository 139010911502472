import React, {useContext} from 'react'
import {Button, Divider, Input} from 'antd'
import RandomPrompt from './RandomPrompt'
import Tips from './Tips'
import {ChatContext} from '@web/hooks/context'
import Icon from './Icon'
const editorSize = { minRows: 1, maxRows: 10 }

export default function ChatEditor({text, media = [], onSend, onTextChange}) {
  let editor
  const [agentId, {editorOptions, examples = []} = {}] = useContext(ChatContext) // TODO This will likely need to be a db lookup by agentId to get chat agent details
  const onChange = e => {
    const text = e.target.value
    onTextChange(text)
  }
  const onSubmit = () => {
    editor.focus()
    onSend()
  }
  const {
    placeholder = 'Ask a question',
    maxLength = 300,
  } = editorOptions || {}
  return (
    <div className="brainstorm-editor-wrapper">
      <div className="space space-center space-end relative">
        <Input.TextArea
          ref={ref => (editor = ref)}
          placeholder={placeholder}
          onChange={onChange}
          autoSize={editorSize}
          value={text}
          maxLength={maxLength}
          className="brainstorm-editor"
          size="large"
        />
        <Button
          className="brainstorm-send-button border-none"
          shape="round"
          disabled={!text}
          onClick={onSubmit}
          type="primary"
          icon={<Icon name="SendArrow" size={20} />}
        />
      </div>
      <Divider className="small" />
      <div className="editor-actions-strip chat">
        <RandomPrompt
          text="Example"
          prompts={examples}
          hidden={!!text}
          onSelect={onTextChange}
        />
        <Tips agentId={agentId} />
      </div>
    </div>
  )
}

