import React, {useState} from 'react'
import {getIdTokenResult} from 'firebase/auth'
import {useIdToken} from 'react-firebase-hooks/auth'

const _claims = null
export function useAuthOven(auth) {
  const [claims, setClaims] = useState(_claims)
  const [user, loadingUser, errorLoadingUser] = useIdToken(auth, {
    onUserChanged: async (user) => {
      if (!user) return
      if (user.isAnonymous) return
      const {claims} = await getIdTokenResult(user)
      setClaims(claims)
    }
  })
  const refreshClaims = () => {
    console.log('refreshClaims')
    getIdTokenResult(user, true)
      .then(({claims}) => setClaims(claims))
  }
  // console.log('useAuthOven', {claims, user})
  return [
    user,
    {...claims, refresh: refreshClaims},
    loadingUser,
    errorLoadingUser
  ]
}
