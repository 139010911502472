import React, {useState} from 'react'
import {Button, message, notification, Space, Tag} from 'antd'
import {buildQuery, colls} from '@web/lib/firestore.db'
import useQueryFirestore from '@web/hooks/useQueryFirestore'
import {ArrowLeftOutlined, ArrowRightOutlined} from '@ant-design/icons'

const Tips = ({agentId}) => {
  const [pairs = [],, error] = useQueryFirestore(
    buildQuery(
      colls['user-tips'],
    {'==': {agentId}},
    {order: [['rank', 'asc'], ['like', 'desc']]}
    )
  )
  const [tipIndex, setTipIndex] = useState(0)
  const onClickPrev = (tipIndex) => {
    if (tipIndex > 0){
      const newIndex = tipIndex - 1
      showTip(newIndex)
      setTipIndex(newIndex)
    }
  }
  const onClickNext = (tipIndex) => {
    if (tipIndex < (pairs.length - 1)) {
      const newIndex = tipIndex + 1
      setTipIndex(newIndex)
      showTip(newIndex)
    }
  }
  const key = 'ad-tip'
  const onClickClose = () => notification.close(key)
  const showTip = (tipIndex) => {
    if (!pairs?.[tipIndex]) {
      message.warn('No Tips Available')
      return
    }
    notification.open({
      key,
      duration: 0,
      message: (
        <Space>
          <Tag>Tip</Tag>
          {pairs[tipIndex][1].title}
        </Space>
      ),
      description: (
        <div className="tip-example">
          <p>Example</p>
          <p dangerouslySetInnerHTML={{__html: pairs?.[tipIndex]?.[1]?.example}} />
        </div>
      ),
      btn: (
        <Space>
          <Button size="large" onClick={onClickClose}>Close</Button>
          <Button.Group size="large">
            <Button
              onClick={() => onClickPrev(tipIndex)}
              icon={<ArrowLeftOutlined/>}
              disabled={tipIndex <= 0}
            />
            <Button
              onClick={() => onClickNext(tipIndex)}
              icon={<ArrowRightOutlined/>}
              disabled={tipIndex >= (pairs.length - 1)}
            />
          </Button.Group>
        </Space>
      ),
      closeIcon: null
    })
  }
  if (!pairs.length) return null
  return (
    <Tag
      className="editor-action-button"
      onClick={() => showTip(tipIndex)}
    >
      TIPS
    </Tag>
  )
}

export default Tips
