import React, {useContext} from 'react'
import {Modal, Input, Typography, notification, Space} from 'antd'
import {addDocument, colls} from '@web/lib/firestore.db'
import {AppContext} from '@web/hooks/context'
import PropTypes from 'prop-types'
import LoginLinkButton from './LoginLinkButton'

FeedbackModal.prototypes = {
  onClose: PropTypes.func.isRequired
}

function FeedbackModal(props) {
  const [text, setText] = React.useState('')
  const [show, setShow] = React.useState(false)
  const [app, user] = useContext(AppContext)
  const [success, setSuccess] = React.useState(false)

  if (!user) return <LoginLinkButton />
  const close = () => setShow(false)
  const open = () => setShow(true)
  const saveFeedback = () => {
    if (!text) return notification.info({message: 'Please enter feedback.'})
    addDocument(colls['user-feedback'], {text, userId: user?.uid || null, app: app?.key || null})
      .then(() => setSuccess(true))
      .then(close)
  }
  return (<><Typography.Link
      type="success"
      className="dotted"
      disabled={show}
      underline
      onClick={open}
    >Request a Feature
  </Typography.Link>
    <Modal
      destroyOnClose
      open={show}
      onCancel={close}
      title="Your Feedback"
      onOk={saveFeedback}
      okText="Send"
      afterClose={() => props.onClose(success)}
    >
      <Space direction="vertical">
        <Input.TextArea
          autoSize={{minRows: 5, maxRows: 10}}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      <Typography.Text type="secondary">
        Request a feature, tell us what you think. We review and consider all submissions.
      </Typography.Text>
      </Space>
    </Modal>
    </>
  )
}

export default FeedbackModal
