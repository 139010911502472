import React, {useState} from 'react'
import random from 'lodash/random'

const RandomPrompt = ({prompts = [], text = 'Try an example', onSelect, hide}) => {
  const [index, setIndex] = useState(random(0, prompts.length - 1))
  if (!prompts.length || hide) return null
  const onClick = e => {
    e.preventDefault()
    e.stopPropagation()
    onSelect(prompts[index])
    setIndex(random(0, prompts.length - 1))
  }
  return (
    <a href="#" onClick={onClick}>
      {text}
    </a>
  )
}

export default RandomPrompt
