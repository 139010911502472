import {notification} from 'antd'
import {ga} from '@web/lib/firebase'

const placement = 'top'
export const notifyAndTrack = {
  error: options => {
    notification.error({
      placement,
      message: options.message,
      description: options.description || null,
    })
    ga.notification('error', options)
  },
  warning: options => {
    notification.warn({
      placement,
      message: options.message,
      description: options.description || null,
    })
    ga.notification('warning', options)
  },
  warn: options => notifyAndTrack.warning({placement, message: options.message, description: options.description || null}),
  info: options => {
    notification.info({
      placement,
      message: options.message,
      description: options.description || null,
    })
    ga.notification('info', options)
  },
  success: options => {
    notification.success({placement, message: options.message, description: options.description || null})
    ga.notification('success', options)
  }
}
