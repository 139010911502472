import React, {lazy, Suspense, useEffect, useState} from 'react'
import {auth} from '@web/lib/firebase'
import {Alert} from 'antd'
import {Route, Switch} from 'wouter'
import Login from '@ai-antd/page/Login'
import SignUp from '@ai-antd/page/SignUp'
import BizLayout from '@ai-antd/page/BizLayout'
import useDocumentFirestore from '@web/hooks/useDocumentFirestore'
import Loading from '@ai-antd/components/Loading'
import {AppContext} from '@web/hooks/context'
import {notifyAndTrack} from '@ai-antd/components/notification'
import Logout from '@ai-antd/page/Logout'
import {useAuthOven} from '@web/hooks/useAuthOven'
import WithSubscription from '@web/ui/containers/WithSubscription'
import PageDrawer from '@ai-antd/page/PageDrawer'
import AgentInfo from '@ai-antd/components/AgentInfo'
import ChatDrawer from '@ai-antd/components/ChatDrawer'
import {colls, getDocument} from '@web/lib/firestore.db'
import uniq from 'lodash/uniq'
import '@ai-antd/page/App.less'
import tree from './nav.config'
import AnonymousAuth from '@web/ui/containers/AnonymousAuth'

const Admin = lazy(() => import('@ai-antd/page/Admin'))
const Learn = lazy(() => import('@ai-antd/page/Learn'))
const Experience = lazy(() => import('@ai-antd/page/Experience'))
const About = lazy(() => import('@ai-antd/page/About'))
const Account = lazy(() => import('@ai-antd/page/Account'))
const Dashboard = lazy(() => import('@ai-antd/page/Dashboard'))
const ovenAppId = process.env.REACT_APP_OVEN_APP_ID

const handleError = error => {
  !!error &&
  notifyAndTrack.error({
    message: "Please refresh the app to continue.",
    internal: error?.message,
    critical: 'App config was not found.'
  })
}

const filterTree = (tree, app) => {
  return app?.workspace ?
    tree :
    tree.filter(item => item.key !== 'dashboard' && item.key !== 'business' && item.key !== 'pro')
}

const App = () => {
  const [user, claims, loadingUser] = useAuthOven(auth)
  const [app = {}, loadingApp, error] = useDocumentFirestore('oven-apps', ovenAppId)
  const [ready, setReady] = useState(false)
  useEffect(() => {
    if (loadingApp || loadingUser || ready) return
    if (!app.root) return
    if (app.agents) return
    const agentIds = uniq([...(app.workspace?.agentIds || []), ...(app.chat?.agentIds || [])])
    if (!agentIds.length) {
      setReady(true)
      return
    }
    Promise.all(agentIds.map(agentId => getDocument(colls['agent-config'], agentId)))
      .then(agentsSnaps => agentsSnaps.reduce((acc, agentSnap) => {
        agentSnap.exists() && (acc[agentSnap.id] = agentSnap.data())
        return acc
      }, {}))
      .then(agents => app.agents = agents)
      .then(() => setReady(true))
  }, [ready, loadingApp, loadingUser, app])
  useEffect(() => handleError(error), [error])
  if (!ready) return <Loading />
  if (!app.root || app.offline) return <Alert
    message="Service is currently unavailable. Please check back later."
    banner
  />
  return (
    <AppContext.Provider value={[app, user, claims, filterTree(tree, app)]}>
      <BizLayout name={app.root.name} domain={app.root.domain}>
        <Switch>
          <Route path="/dashboard">
            <PageDrawer>
              <Suspense fallback={<Loading />}>
                <Dashboard />
              </Suspense>
            </PageDrawer>
          </Route>
          {app.home && !app.home.hide &&
            <Route path="/learn">
              <PageDrawer title="Learn">
                <Suspense fallback={<Loading />}>
                  <Learn />
                </Suspense>
              </PageDrawer>
            </Route>
          }
          {app.admin && app.admin.enable &&
            <Route path="/admin">
              <PageDrawer title="Admin">
                <Suspense fallback={<Loading />}>
                  <Admin />
                </Suspense>
              </PageDrawer>
            </Route>
          }
          <Route path="/chat">
            <AnonymousAuth>
              <ChatDrawer show={true} controlled />
            </AnonymousAuth>
          </Route>
          <Route path="/chat/:agentId">
            <AnonymousAuth>
              <ChatDrawer show={true} controlled />
            </AnonymousAuth>
          </Route>
          {app.account && !app.account.hide && [
            <Route path="/login" key="login">
              <PageDrawer>
                <Login appname={app.root.name}/>
              </PageDrawer>
            </Route>,
            <Route path="/sign-up" key="sign-up">
              <PageDrawer>
                <SignUp/>
              </PageDrawer>
            </Route>,
            <Route path="/logout" key="logout">
              <Logout />
            </Route>,
            <Route path="/account" key="account">
              <PageDrawer>
                <Suspense fallback={<Loading />}>
                  <Account />
                </Suspense>
              </PageDrawer>
            </Route>,
            <Route path="/get-started" key="get-started">
              <PageDrawer title="Get Started">
                <Suspense fallback={<Loading />}>
                  <Account onboarding />
                </Suspense>
              </PageDrawer>
            </Route>
          ]}
          {app.about && !app.about.hide && [
            <Route path="/about" key="about">
              <PageDrawer>
                <Suspense fallback={<Loading />}>
                  <About/>
                </Suspense>
              </PageDrawer>
            </Route>,
            <Route path="/about/:tab" key="about-tab">
              {({tab}) => <PageDrawer><Suspense fallback={<Loading />}>
                <About tabKey={tab}/>
              </Suspense></PageDrawer>}
            </Route>]
          }
          {app.workspace && <Route path="/basic/:agentId">
            {({agentId}) => {
              return <PageDrawer>
                <Suspense fallback={<Loading/>}>
                  <Experience agentId={agentId}/>
                </Suspense>
              </PageDrawer>
            }}
          </Route>}
          {app.workspace && <Route path="/business/:agentId">
            {({agentId}) => {
              return <PageDrawer>
                <WithSubscription
                  sub="business"
                  fallback={<AgentInfo business productIndex={0} agentId={agentId}/>}
                >
                  <Suspense fallback={<Loading/>}>
                    <Experience agentId={agentId}/>
                  </Suspense>
                </WithSubscription></PageDrawer>
            }}
          </Route>}
        </Switch>
      </BizLayout>
    </AppContext.Provider>
  )
};
export default App
