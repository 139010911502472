import React, {useState, useContext} from 'react'
import now from 'lodash/now'
import {ga} from '@web/lib/firebase'
import {addDocument_NoTimestamp, colls, safeValueForDB} from '@web/lib/firestore.db'
import LinkParser from './LinkParser'
import {AppContext, ChatContext} from '@web/hooks/context'
export default function ChatManager({collectionName, children, onError, onSuccess, onSend, onInfo, onComplete}) {
  const [{root}, user] = useContext(AppContext)
  const [agentId, agent, input = {}] = useContext(ChatContext)
  const [text, setText] = useState(input?.chat?.response?.answers?.[0]?.text || input?.text || '')
  const [media, setMedia] = useState([])
  if (!collectionName) return onError({
    message: 'Collection name is required in Editor.',
    agentId,
    uid: user?.uid,
  })
  const sendRequest = async (tone) => {
    console.log('sendRequest', text)
    if (!text) return onInfo({
        message: 'Please enter a prompt',
      })
    if (!agentId) return onError({
      message: "Please choose an ai agent."
    })
    onSend()
    const ts = now()
    const prompt = {
      text,
      agentId,
      userId: user.uid,
      app: root.key,
      createdTs: ts,
      updatedTs: ts,
    }
    setText('')
    if (media.length) prompt.media = media
    if (tone) prompt.tone = tone
    if (agent?.augment?.enable) prompt.augment = agent?.augment
    try {
      return addDocument_NoTimestamp(
        colls[collectionName],
        safeValueForDB({prompt})
      ).then(() => {
        onComplete()
        onSuccess()
        ga.log('add-success-prompt', {value: agentId})
      }).catch(e => {
        onComplete()
        onError({
          value: `Add document to ${collectionName} failed`,
          code: e.code,
          message: 'An error occurred.',
          description: e?.message === 'internal' ? 'Unable to complete your request. Try again later.' : e?.message,
        })
      })
    } catch(e) {
      onComplete()
      onError({
        value: 'Function sendRequest failed.',
        code: e.code,
        message: 'Oops, an error occurred.',
        description: 'Please try again.',
        userId: user.uid,
      })
    }
  }
  const onTextChange = text => {
    setText(text)
  }
  const onMediaChange = media => {
    setMedia(media)
  }
  return React.cloneElement(React.Children.only(children), {
    text, media, onSend: sendRequest, onTextChange, onMediaChange, onError, onInfo
  })
}

ChatManager.LinkParser = LinkParser
