import React, {useContext} from 'react'
import {AppContext} from '@web/hooks/context'
// import PriceTable from '@ai-antd/components/PriceTable'
// import isArray from 'lodash/isArray'
// import get from 'lodash/get'

// const hasClaims = (requiredSub, userClaims = {}) => {
//   return userClaims.sub === requiredSub
// }
export default function WithSubscription({sub: requiredSub, ...props}) {
  const [app,user,userClaims] = useContext(AppContext)
  if (!user) return props.children
  if (!app?.products?.enable) return props.children
  return ((userClaims.sub === requiredSub) || true) ?
    props.children :
    props.fallback // || <PriceTable/>
}
