import React, {useContext} from 'react'
import ChatManager from '@web/ui/containers/ChatManager'
import ChatThread from './ChatThread'
import {AppContext, WindowContext} from '@web/hooks/context'
import ChatEditor from './ChatEditor'
import {notifyAndTrack} from './notification'

const onSend = () => null
const onSuccess = () => null
const onComplete = () => null
const onInfo = info => notifyAndTrack.info(info)
const onError = error => notifyAndTrack.error(error)

export default function Chat() {
  const [, user] = useContext(AppContext)
  const [isMobile] = useContext(WindowContext)
  return (
    <>
      <div className={`brainstorm-wrapper ${isMobile ? "mobile" : "desktop"}`}>
        {user && <ChatThread />}
        <ChatManager
          collectionName="ai-chat"
          onSend={onSend}
          onError={onError}
          onInfo={onInfo}
          onSuccess={onSuccess}
          onComplete={onComplete}
        >
          <ChatManager.LinkParser>
            <ChatEditor />
          </ChatManager.LinkParser>
        </ChatManager>
      </div>
    </>
  )
}
