import React from 'react'
import {Collapse, Space} from 'antd'
import {Link} from 'wouter'

const Industries = () => {
  return (
    <Collapse accordion>
      <Collapse.Panel header="Clothing Store" key="1">
        <Space direction="vertical">
          <p>Create Ads, fun social media posts, marketing emails, professional sales messages, and more to promote your store and apparel for sale.</p>
          <Link to="/login">Get Started Free</Link>
        </Space>
      </Collapse.Panel>
      <Collapse.Panel header="Used Car Sales" key="2">
        <Space direction="vertical">
          <p>Create automobile listings, social media promos, marketing emails, sales messages, and more to professionally promote your shop and vehicles for sale.</p>
          <Link to="/login">Get Started Free</Link>
        </Space>
      </Collapse.Panel>
      <Collapse.Panel header="Hotel & Dinning" key="3">
        <Space direction="vertical">
          <p>Create unique branded ads, social media narratives, blog posts, marketing emails and more to engage traveler and diners.</p>
          <Link to="/login">Get Started Free</Link>
        </Space>
      </Collapse.Panel>
      <Collapse.Panel header="Catering" key="4">
        <Space direction="vertical">
          <p>Create quality ads, social media posts, blog posts, professional marketing content and more to present a professional voice to your audience.</p>
          <Link to="/login">Get Started Free</Link>
        </Space>
      </Collapse.Panel>
      <Collapse.Panel header="Mobile Phone" key="5">
        <Space direction="vertical">
          <p>Create fun ads, witty social media posts, relevant marketing content and much more to remain top-of-mind to your customers.</p>
          <Link to="/login">Get Started Free</Link>
        </Space>
      </Collapse.Panel>
      <Collapse.Panel header="Banking" key="6">
        <Space direction="vertical">
          <p>Create professional ads, relevant social media posts, marketing content and much more to build trust and engage your customers.</p>
          <Link to="/login">Get Started Free</Link>
        </Space>
      </Collapse.Panel>
      <Collapse.Panel header="Fashion Designer" key="7">
        <Space direction="vertical">
          <p>Create slick ads, develop your voice with consistent social media tone, blog posts and other marketing content to grow your fan base and drive sales.</p>
          <Link to="/login">Get Started Free</Link>
        </Space>
      </Collapse.Panel>
    </Collapse>
  )
}

export default Industries
