import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {Button, Card, Divider, Space, Tag, Typography} from 'antd'
import useQueryFirestore from '@web/hooks/useQueryFirestore'
import {buildQuery, colls} from '@web/lib/firestore.db'
import {AppContext} from '@web/hooks/context'
import Loading from './Loading'
import {Link} from 'wouter'

LatestActivity.propTypes = {
  count: PropTypes.number.isRequired,
}

function LatestActivity({count = 1}) {
  const [app, user = {}] = useContext(AppContext)
  const [pairs = [], dataLoading, error] = useQueryFirestore(
    user?.uid ? buildQuery(colls['ai-chat'],
      {'==': {
        "prompt.userId": user.uid,
          "prompt.app": app.root.key,
        }
      }, {order: [['prompt.createdTs', 'desc']], limit: count}) :
      null
  )
  if (dataLoading) return <Loading />
  if (error || !pairs.length) return <Card>
    <Card.Meta description="Your latest activity will show here." />
  </Card>
  return pairs.map(([chatId, chat = {}]) => <Card
    bordered
    key={chatId}
    title={<Space><Tag>Ai</Tag><Typography.Text>{app.agents?.[chat.prompt?.agentId]?.name || 'Agent'}</Typography.Text></Space>}
    extra={<Link href={`/business/${chat.prompt.agentId}`}><Button>Continue</Button></Link>}
    >
    <Card.Meta
    description={
      <Space direction="vertical">
        <Typography.Paragraph className="muted">
          {chat?.prompt?.text}
        </Typography.Paragraph>
        <Divider className="no-top" />
        <Typography.Paragraph>{chat.response?.answers?.[0]?.text?.trim()}</Typography.Paragraph>
      </Space>
    }/>
    </Card>
  )
}

export default LatestActivity
