import {firebaseApp} from './firebase'
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions'
// const functions = getFunctions(firebaseApp,'europe-west3');
const functions = getFunctions(firebaseApp);
// process.env.NODE_ENV === 'development' && connectFunctionsEmulator(functions, window.location.hostname, 5001);
const getLinkData = httpsCallable(functions, 'v1-api-getLinkData')
const openAiContent = httpsCallable(functions, 'openai-content')
const openAiBrainstorm = httpsCallable(functions, 'openai-brainstorm')
const openAiDemo = httpsCallable(functions, 'openai-demo')

export const getLinkMeta = async url => await getLinkData({ url })
export const completion = async ({promptOptions, modelOptions}) => await openAiContent({ promptOptions, modelOptions })
export const brainstorm = async ({promptOptions, modelOptions, history = []}) => await openAiBrainstorm({ promptOptions, modelOptions, history })
export const demo = async ({promptOptions, modelOptions}) => await openAiDemo({ promptOptions, modelOptions })
