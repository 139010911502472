import React from 'react'
import MoreDetails from '@web/ui/components/MoreDetails'
import {Link} from 'wouter'
import PropTypes from 'prop-types'
import {Typography, Space, Divider} from 'antd'
import TimeAgo from 'react-timeago'

Post.propTypes = {
  article: PropTypes.object
}
export default function Post({article}) {
  return <div className="ai-learn-content">
    <TimeAgo className="muted" live date={new Date(1674544368015).getTime()} />
    <Typography.Title level={5}>{article?.title}</Typography.Title>
    <Typography.Paragraph type="secondary">
      The success of any business depends on its ability to build strong relationships with customers. But how can you make a lasting impression on your customers in limited time? The answer is simple – by taking just 30 minutes out of your day to focus on customer experience and satisfaction. Here are 5 things that you can do in less than 30 minutes to make your customers love your business.
    </Typography.Paragraph>
    <MoreDetails title="Read more">
      <Space direction="vertical">
        {article.list.map((listItem, index) => {
          return <div key={index}>
            <Typography.Paragraph>{listItem?.text}</Typography.Paragraph>
            <Link href={`${listItem?.action?.[1]}?text=${listItem?.action?.[2]}`}>{listItem?.action?.[0]}</Link>
            <Divider className="small" />
          </div>
        })}
      </Space>
    </MoreDetails>
    <Typography.Paragraph>{article?.body}</Typography.Paragraph>
  </div>
}
