import React from 'react'
import {Layout} from 'antd'
import {useState} from 'react'

const { Sider } = Layout

const DesktopSidebar = ({className = '', children, width = 290}) => {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <Sider
      width={width}
      className={`desktop-sider ${className}`}
      collapsible
      collapsed={collapsed}
      onCollapse={value => setCollapsed(value)}
      trigger={null}
    >
      {children}
    </Sider>
  )
}

export default DesktopSidebar
