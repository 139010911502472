import React from 'react';
import {Space, Tag, Typography} from 'antd'
import PropTypes from 'prop-types'

NavMenuItem.propTypes = {
  inactive: PropTypes.bool,
  isNew: PropTypes.bool,
  selected: PropTypes.bool,
  text: PropTypes.string.isRequired,
};
function NavMenuItem(props) {
  return <div className={props.selected ? 'nav-item selected' : 'nav-item'}>
    <Space>
      <Typography.Text className="nav-menu-item-text" type={props.inactive ? 'secondary' : 'primary'}>{props.text}</Typography.Text>
      {props.isNew && <Tag color="success">new</Tag>}
    </Space>
  </div>
}

export default NavMenuItem;
