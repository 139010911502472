import React, {useRef, useState, useEffect} from 'react'
import {collection, addDoc, onSnapshot} from 'firebase/firestore'
import {firestore} from '@web/lib/firebase'
export default function useStripeSubscriptionCheckout() {
  const [processing, setProcessing] = useState(false)
  const [checkoutUrl, setCheckoutUrl] = useState()
  const [sessionId, setSessionId] = useState('')
  const [error, setError] = useState(false)
  const sessionRef = useRef(null)

  useEffect(() => {
    sessionRef.current &&
      onSnapshot(sessionRef.current, snap => {
        const {error, url} = snap.data()
        if (error) return setError(error)
        if (url) return setCheckoutUrl(url)
      })
  }, [sessionRef.current])
  /*
   Trigger stopProcessing from parent page if the checkoutURL will not be used immediately
   Otherwise the processing flag will remain active.
  */
  const stopProcessing = () => setProcessing(false)
  const requestStripeCheckoutUrl = (
    {userId,
      priceId,
      successUrl,
      cancelUrl,
      allowTrial = true,
      allowPromoCode = true,
      applyPromoCode = null,
      collectShippingAddress = false,
    }) => {
    setProcessing(priceId)

    addDoc(collection(firestore, `customers-stripe/${userId}/checkout_sessions`),
      {price: priceId,
        success_url: successUrl,
        cancel_url: cancelUrl,
        trial_from_plan: allowTrial,
        allow_promotion_codes: allowPromoCode,
        promotion_code: applyPromoCode,
        collect_shipping_address: collectShippingAddress,
      })
      .then(docRef => {
        sessionRef.current = docRef
        setSessionId(docRef.id)
      })
      .catch(error => {
        setError(error)
        setProcessing(false)
      })
  }
  return [checkoutUrl, processing, error, requestStripeCheckoutUrl, stopProcessing]
}