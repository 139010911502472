import React, {useContext} from 'react'
import {Button, Divider, Space, Tabs, Typography} from 'antd'
import {Link, useLocation} from 'wouter'
import TagsList from './TagsList'
import {ga} from '@web/lib/firebase'
import {RightOutlined} from '@ant-design/icons'
import FeaturesList from './FeaturesList'
import Industries from './Industries'
import {AppContext, WindowContext} from '@web/hooks/context'

const buttonWrapperStyle = {width: '220px'}

const Wrapper = ({isMobile, height, children}) => {
  return (<div className="splash-card-wrapper"
    style={{
      minHeight: isMobile ? '100lvh' : height - 120,
      padding: isMobile ? '20px' : '20px 12% 20px 5%',
    }}
  >
    {children}
  </div>
  )
}

const SplashCard = ({agent}) => {
  const [, setLocation] = useLocation()
  const [isMobile,, height] = useContext(WindowContext)
  const [app] = useContext(AppContext)
  const firstButtonOnClick = e => {
    setLocation('/sign-up')
    ga.click(e)
  }
  const [feature = {}] = agent.features
  return (
    <Wrapper
      isMobile={isMobile}
      height={height}
    >
      <Typography.Title type="warning">{feature.actionVerb}.</Typography.Title>
      <Typography.Title>{feature.feature}</Typography.Title>
      <TagsList tags={feature.keywords?.slice(0, 3)} type="secondary" />
      <Divider />
      <Space direction="vertical" style={buttonWrapperStyle}>
        <Button type="primary" block title="Get Started" size="large" onClick={firstButtonOnClick}>
          Get Started
        </Button>
        {/*<Typography.Text type="success">Free for a limited time</Typography.Text>*/}
      </Space>
    </Wrapper>
  )
}

const Home = ({card, navigateNext}) => {
  const [, setLocation] = useLocation()
  const [isMobile,,height] = useContext(WindowContext)
  const firstButtonOnClick = (e)=> {
    setLocation(`/login`)
    ga.click(e)
  }
  return <Wrapper isMobile={isMobile} height={height}>
    <Typography.Title type="warning">{card.title}.</Typography.Title>
    <Typography.Title>{card.subTitle}</Typography.Title>
    <TagsList tags={card.tags} type="secondary" />
    <Divider />
    <Space direction="vertical">
      <Space>
        <Button title="Splash Landing: Get Started" size="large" onClick={firstButtonOnClick}>
          Get Started
        </Button>
        <Button title="Splash Landing: Tell Me More" size="large" icon={<RightOutlined />} onClick={navigateNext}>
          Tell me more
        </Button>
      </Space>
      {card?.extra?.text && !card?.extra?.hidden && <Link className="splash-extra-link" to={card.extra?.url}>
        {card.extra.text}
      </Link>
      }
    </Space>
  </Wrapper>
}

const Summary = ({agents = {}, workspace = {}}) => {
  const [, setLocation] = useLocation()
  const [isMobile,,height] = useContext(WindowContext)
  const goToSignup = e => {
    ga.click(e)
    setLocation(`/sign-up`)
  }
  return <Wrapper isMobile={isMobile} height={height}>
    <Typography.Title type="warning">Everything You Need.</Typography.Title>
    <Tabs
      items={[
        {
          label: "Features",
          key: "features",
          children: <FeaturesList
            agents={agents}
            agentIds={workspace.agentIds}
          />
        },
        {
          label: "Industries",
          key: "industries",
          children: <Industries />
        }
      ]}
      tabBarExtraContent={isMobile &&
        <Link to="/login" onClick={ga.click}>
          <Button
            title="Start Now: Splash Last"
            type="primary"
          >
            Start Now
          </Button>
        </Link>
    }
    />
    <Divider />
    <Button
      size="large"
      type="primary"
      block
      onClick={goToSignup}>
      Get Started Today
    </Button>
    <Divider />
  </Wrapper>
}

SplashCard.Home = Home
SplashCard.Summary = Summary
export default SplashCard


// {/*{agent.demo && !agent.demo.hide &&*/}
// {/*  <Link to="/sign-up" onClick={ga.click}>*/}
// {/*    <Button title="Demo" size="large" type="primary">*/}
// {/*      Demo*/}
// {/*    </Button>*/}
// {/*  </Link>*/}
// {/*}*/}
// {/*{feature.description && !feature?.extra?.hide &&*/}
// {/*  <Link*/}
// {/*    className="splash-extra-link"*/}
// {/*    to={`workspace/${agentId}/info`}*/}
// {/*  >More info*/}
// {/*  </Link>*/}
// {/*}*/}