import React from 'react'

export function getView(width) {
  let newView = "MobileView";
  if (width > 1220) {
    newView = "DesktopView";
  } else if (width > 767) {
    newView = "TabView";
  }
  return newView;
}

const useWindowSize = () => {
  const isClient = typeof window === 'object'

  const getSize = React.useCallback(
    () => {
      const width = isClient ? window.innerWidth : undefined
      return {
        width,
        height: isClient ? window.innerHeight : undefined,
        isMobile: getView(width) === "MobileView"
      }
    },
    [isClient]
  );

  const [size, setSize] = React.useState(getSize);

  React.useEffect(() => {
    if (!isClient) {
      return false
    }
    const onHandleResize = () => {
      setSize(getSize)
    }
    window.addEventListener('resize', onHandleResize)
    return () => window.removeEventListener('resize', onHandleResize)
  }, [getSize, isClient])

  return size
};

export default useWindowSize
