import {
  BorderlessTableOutlined, BulbOutlined,
  CarryOutOutlined, ClockCircleOutlined,
  HomeOutlined,
  LockOutlined,
  MessageOutlined,
  UnlockOutlined
} from '@ant-design/icons'
import React from 'react'

const config = [
  {
    title: 'Dashboard',
    key: 'dashboard',
    path: '/dashboard',
    icon: <UnlockOutlined className="muted" />,
    switcherIcon: <HomeOutlined className="muted" /> //<FormOutlined className="muted" />,
  },
  {
    title: 'Chat',
    key: 'chat',
    isNew: true,
    path: '/chat/ai-assistant',
    icon: <CarryOutOutlined />,
    switcherIcon: <MessageOutlined className="muted" />,
  },
  {
    title: 'Learn',
    key: 'learn',
    path: '/learn',
    isNew: true,
    icon: <CarryOutOutlined />,
    switcherIcon: <BulbOutlined className="muted" />,
  },
  {
    title: 'Business',
    key: 'business',
    meta: {
      agentIds: ['agent-1', 'agent-2'],
    },
    children: [
      {
        title: 'Perfect Grammar',
        key: 'basic-correct-grammar',
        path: '/basic/ai-correct-grammar',
        icon: <CarryOutOutlined />,
        switcherIcon: <BorderlessTableOutlined className="muted" />,
      },
      {
        title: 'Customer Email',
        key: 'customer-email',
        path: '/basic/ai_draft_customer_email',
        switcherIcon: <BorderlessTableOutlined className="muted" />,
      },
      {
        title: 'Instagram',
        key: 'social-instagram',
        path: '/basic/ai-instagram',
        switcherIcon: <BorderlessTableOutlined className="muted" />,
      },
      {
        title: 'Twitter',
        key: 'social-twitter',
        path: '/basic/ai-twitter',
        switcherIcon: <BorderlessTableOutlined className="muted" />,
      },
      {
        title: 'Facebook',
        key: 'social-facebook',
        path: '/basic/ai-facebook',
        switcherIcon: <BorderlessTableOutlined className="muted" />,
      },
      {
        title: 'Blog',
        key: 'blog',
        isNew: true,
        children: [
          {
            title: 'Quick Blog',
            key: 'quick-blog-post',
            path: '/business/ai-write-article',
            icon: <CarryOutOutlined />,
            switcherIcon: <BorderlessTableOutlined className="muted" />,
          },
          {
            title: 'Advanced Blog',
            key: 'advanced-blog-post',
            path: '/business/ai-write-article-advanced',
            inactive: true,
            icon: <CarryOutOutlined />,
            switcherIcon: <ClockCircleOutlined className="muted" />,
          },
        ]
      },
      {
        title: 'E-Commerce',
        key: 'e-commerce',
        isNew: true,
        children: [
      {
        title: 'Jumia Listings',
        key: 'product-jumia-listing',
        path: '/business/ai-jumia-listing',
        icon: <CarryOutOutlined />,
        switcherIcon: <BorderlessTableOutlined className="muted" />,
      },
      {
        title: 'Alibaba Listings',
        key: 'product-alibaba-listing',
        path: '/business/ai-alibaba-listing',
        icon: <CarryOutOutlined />,
        switcherIcon: <BorderlessTableOutlined className="muted" />,
      }
      ]},
    ],
  },
  {
    title: 'Pro',
    key: 'pro',
    switcherIcon: <LockOutlined />,
    children: [
      {
        title: 'Website',
        key: 'pro-website',
        inactive: true,
        icon: <CarryOutOutlined />,
        switcherIcon: <ClockCircleOutlined className="muted" />,
        children: [
          {
            title: 'Brand Website',
            key: 'business-ai-brand-website',
            path: '/business/ai-brand-website-builder',
            inactive: true,
            icon: <CarryOutOutlined />,
            switcherIcon: <ClockCircleOutlined className="muted" />,
          },
          {
            title: 'Brand Page',
            key: 'business-ai-brand-landing',
            path: '/business/ai-landing-page',
            inactive: true,
            icon: <CarryOutOutlined />,
            switcherIcon: <ClockCircleOutlined className="muted" />,
          },
        ],
      },
      {
        title: 'Sell',
        key: 'business-sell',
        inactive: true,
        icon: <CarryOutOutlined />,
        switcherIcon: <ClockCircleOutlined className="muted" />,
        children: [
          {
            title: 'Product Landing Page',
            key: 'product-landing-page',
            inactive: true,
            path: '/business/ai-product-landing-page',
            icon: <CarryOutOutlined />,
            switcherIcon: <ClockCircleOutlined className="muted" />,
          },
          {
            title: 'Product Blog',
            key: 'product-blog',
            inactive: true,
            path: '/business/ai-write-product-article',
            icon: <CarryOutOutlined />,
            switcherIcon: <ClockCircleOutlined className="muted" />,
          },
          {
            title: 'Product FAQ',
            key: 'product-faq',
            path: '/business/ai-product-faq',
            inactive: true,
            icon: <CarryOutOutlined />,
            switcherIcon: <ClockCircleOutlined className="muted" />,
          },
          {
            title: 'Add Product',
            key: 'product-add',
            path: '/business/ai-products',
            inactive: true,
            icon: <CarryOutOutlined />,
            switcherIcon: <ClockCircleOutlined className="muted" />,
          },
          {
            title: 'Instant Store',
            key: 'business-ai-instant-store',
            path: '/business/ai-sell-instant-store',
            inactive: true,
            icon: <CarryOutOutlined />,
            switcherIcon: <ClockCircleOutlined className="muted" />,
          },
        ],
      },
      {
        title: 'Hire',
        key: 'pro-hire',
        inactive: true,
        icon: <CarryOutOutlined />,
        switcherIcon: <ClockCircleOutlined className="muted" />,
        children: [
          {
            title: 'Job Description',
            key: 'team-hire-roles-csa-job-description',
            path: '/business/ai-write-job-description',
            inactive: true,
            icon: <CarryOutOutlined />,
            switcherIcon: <LockOutlined className="muted" />,
          },
          {
            title: 'Interview Questions',
            key: 'team-hire-roles-csa-interview',
            path: '/business/ai-write-interview-questions',
            inactive: true,
            icon: <CarryOutOutlined />,
            switcherIcon: <LockOutlined className="muted" />,
          },
        ]
      },
    ]
  },
];

export default config

// children: [
//   {
//     title: 'Home Page',
//     key: 'business-ai-brand-website-home',
//     icon: <CarryOutOutlined />,
//     switcherIcon: <LockOutlined className="muted" />,
//   },
//   {
//     title: 'About',
//     key: 'business-ai-brand-website-about',
//     icon: <CarryOutOutlined />,
//     switcherIcon: <LockOutlined className="muted" />,
//     // switcherIcon: <Icon name="RightArrowOutlined" size={20} />,
//   },
//   {
//     title: 'FAQ',
//     key: 'business-ai-brand-website-faq',
//     icon: <CarryOutOutlined />,
//     switcherIcon: <LockOutlined className="muted" />,
//   },
// ],
// {
//   title: 'Customers',
//   key: 'customer',
//   switcherIcon: <LockOutlined className="muted" />,
//   children: [
//     // {
//     //   title: 'Email',
//     //   key: 'customer-email',
//     //   path: '/basic/agent/ai_draft_customer_email',
//     //   switcherIcon: <LockOutlined className="muted" />,
//     // },
//     {
//       title: 'Message',
//       key: 'customer-message',
//       path: '/basic/agent/ai-draft-message',
//       switcherIcon: <LockOutlined className="muted" />,
//     },
//   ]
// },
// {
//   title: 'Promote',
//   key: 'business-promote',
//   switcherIcon: <LockOutlined className="muted" />,
//   children: [
//     {
//       title: 'Plan',
//       key: 'business-1001-marketing-plan',
//       switcherIcon: <LockOutlined className="muted" />,
//       children: [
//         {
//           title: 'SEO',
//           key: 'business-1001-marketing-plan-seo',
//           icon: <CarryOutOutlined />,
//           switcherIcon: <LockOutlined className="muted" />,
//         },
//         {
//           title: 'Announcements',
//           key: 'business-1001-marketing-plan-announcements',
//           icon: <CarryOutOutlined />,
//           switcherIcon: <LockOutlined className="muted" />,
//         },
//         {
//           title: 'Email',
//           key: 'business-1001-marketing-plan--email',
//           icon: <CarryOutOutlined />,
//           switcherIcon: <LockOutlined className="muted" />,
//         },
//       ],
//     },
//     {
//       title: 'Organic',
//       key: 'business-promote-on',
//       switcherIcon: <LockOutlined className="muted" />,
//       children: [
//         {
//           title: 'Better Business Bureau',
//           key: 'business-promote-on-bbb',
//           icon: <CarryOutOutlined />,
//           switcherIcon: <LockOutlined className="muted" />,
//         },
//         {
//           title: 'Google Local',
//           key: 'business-promote-on-google-local',
//           icon: <FormOutlined />,
//           switcherIcon: <LockOutlined className="muted" />,
//         },
//       ],
//     },
//   ]
// },
// {
//   title: 'Social Media',
//   key: 'social',
//   switcherIcon: <LockOutlined className="muted" />,
//   children: [
//     {
//       title: 'Instagram',
//       key: 'social-instagram',
//       path: '/basic/ai-instagram',
//       switcherIcon: <LockOutlined className="muted" />,
//       // children: [
//       //   {
//       //     title: 'Bio',
//       //     key: 'social-instagram-bio',
//       //     icon: <CarryOutOutlined />,
//       //     switcherIcon: <LockOutlined className="muted" />,
//       //   },
//       //   {
//       //     title: 'Post',
//       //     key: 'social-instagram-post',
//       //     icon: <CarryOutOutlined />,
//       //     switcherIcon: <LockOutlined className="muted" />,
//       //   },
//       //   {
//       //     title: 'Ads',
//       //     key: 'social-instagram-ads',
//       //     icon: <CarryOutOutlined />,
//       //     switcherIcon: <LockOutlined className="muted" />,
//       //   },
//       // ],
//     },
//     {
//       title: 'Facebook',
//       key: 'social-facebook',
//       path: '/basic/ai-facebook',
//       //icon: <CarryOutOutlined />,
//       switcherIcon: <LockOutlined className="muted" />,
//       // children: [
//       //   {
//       //     title: 'Bio',
//       //     key: 'social-facebook-bio',
//       //     icon: <CarryOutOutlined />,
//       //     switcherIcon: <LockOutlined className="muted" />,
//       //   },
//       //   {
//       //     title: 'Post',
//       //     key: 'social-facebook-post',
//       //     icon: <CarryOutOutlined />,
//       //     switcherIcon: <LockOutlined className="muted" />,
//       //   },
//       //   {
//       //     title: 'Message',
//       //     key: 'social-facebook-message',
//       //     icon: <CarryOutOutlined />,
//       //     switcherIcon: <LockOutlined className="muted" />,
//       //   },
//       //   {
//       //     title: 'Ads',
//       //     key: 'social-facebook-ads',
//       //     icon: <CarryOutOutlined />,
//       //     switcherIcon: <LockOutlined className="muted" />,
//       //   },
//       // ],
//     },
//     {
//       title: 'WhatsApp',
//       key: 'social-whatsapp-profile',
//       path: '/basic/ai-whatsapp',
//       icon: <FormOutlined />,
//       switcherIcon: <LockOutlined className="muted" />,
//     },
//     // {
//     //   title: 'Bio Links Page',
//     //   key: 'social-links',
//     //   icon: <FormOutlined />,
//     //   switcherIcon: <LockOutlined className="muted" />,
//     // },
//   ]
// },
// children: [
//   {
//     title: 'Bio',
//     key: 'social-instagram-bio',
//     icon: <CarryOutOutlined />,
//     switcherIcon: <LockOutlined className="muted" />,
//   },
//   {
//     title: 'Post',
//     key: 'social-instagram-post',
//     icon: <CarryOutOutlined />,
//     switcherIcon: <LockOutlined className="muted" />,
//   },
//   {
//     title: 'Ads',
//     key: 'social-instagram-ads',
//     icon: <CarryOutOutlined />,
//     switcherIcon: <LockOutlined className="muted" />,
//   },
// ],
// children: [
//   {
//     title: 'Bio',
//     key: 'social-instagram-bio',
//     icon: <CarryOutOutlined />,
//     switcherIcon: <LockOutlined className="muted" />,
//   },
//   {
//     title: 'Post',
//     key: 'social-instagram-post',
//     icon: <CarryOutOutlined />,
//     switcherIcon: <LockOutlined className="muted" />,
//   },
//   {
//     title: 'Ads',
//     key: 'social-instagram-ads',
//     icon: <CarryOutOutlined />,
//     switcherIcon: <LockOutlined className="muted" />,
//   },
// ],
// children: [
//   {
//     title: 'Bio',
//     key: 'social-facebook-bio',
//     icon: <CarryOutOutlined />,
//     switcherIcon: <LockOutlined className="muted" />,
//   },
//   {
//     title: 'Post',
//     key: 'social-facebook-post',
//     icon: <CarryOutOutlined />,
//     switcherIcon: <LockOutlined className="muted" />,
//   },
//   {
//     title: 'Message',
//     key: 'social-facebook-message',
//     icon: <CarryOutOutlined />,
//     switcherIcon: <LockOutlined className="muted" />,
//   },
//   {
//     title: 'Ads',
//     key: 'social-facebook-ads',
//     icon: <CarryOutOutlined />,
//     switcherIcon: <LockOutlined className="muted" />,
//   },
// ],
// {
//   title: <Space><Typography.Text type="secondary">Promote</Typography.Text><Tag hidden>Coming Soon</Tag></Space>,
//   key: 'product-1001',
//   switcherIcon: <ClockCircleOutlined className="muted" />,
//   // children: [
//     // {
//     //   title: <Space><Typography.Text type="secondary">Details</Typography.Text><Tag hidden>Coming Soon</Tag></Space>,
//     //   key: 'product-1001-details',
//     //   path: '/business/ai-product/1001',
//     //   icon: <CarryOutOutlined />,
//     //   switcherIcon: <ClockCircleOutlined className="muted" />,
//     //   // children: [
//     //   //   {
//     //   //     title: 'Description',
//     //   //     key: 'product-1001-details-description',
//     //   //     icon: <CarryOutOutlined />,
//     //   //     switcherIcon: <LockOutlined className="muted" />,
//     //   //   },
//     //   //   {
//     //   //     title: 'Tags',
//     //   //     key: 'product-1001-details-tags',
//     //   //     icon: <CarryOutOutlined />,
//     //   //     switcherIcon: <LockOutlined className="muted" />,
//     //   //   },
//     //   //   {
//     //   //     title: 'Images',
//     //   //     key: 'product-1001-details-images',
//     //   //     icon: <CarryOutOutlined />,
//     //   //     switcherIcon: <LockOutlined className="muted" />,
//     //   //   },
//     //   //   {
//     //   //     title: 'Features',
//     //   //     key: 'product-1001-details-features',
//     //   //     icon: <CarryOutOutlined />,
//     //   //     switcherIcon: <LockOutlined className="muted" />,
//     //   //   },
//     //   //   {
//     //   //     title: 'Benefits',
//     //   //     key: 'product-1001-details-benefits',
//     //   //     icon: <CarryOutOutlined />,
//     //   //     switcherIcon: <LockOutlined className="muted" />,
//     //   //   },
//     //   // ]
//     // },
//     // {
//     //   title: <Space><Typography.Text type="secondary">LandingPage</Typography.Text><Tag hidden>Coming Soon</Tag></Space>,
//     //   key: 'product-1001-sell-product-page',
//     //   path: '/business/ai-product-landing-page/1001',
//     //   icon: <CarryOutOutlined />,
//     //   switcherIcon: <ClockCircleOutlined className="muted" />,
//     // },
//     // // {
//     // //   title: 'FAQ',
//     // //   key: 'product-1001-faq',
//     // //   icon: <CarryOutOutlined />,
//     // //   children: [
//     // //     {
//     // //       title: 'Customer FAQ',
//     // //       key: 'product-1001-faq-customer',
//     // //       icon: <CarryOutOutlined />,
//     // //       switcherIcon: <LockOutlined className="muted" />,
//     // //     },
//     // //     {
//     // //       title: 'Employee FAQ',
//     // //       key: 'product-1001-faq-employee',
//     // //       icon: <CarryOutOutlined />,
//     // //       switcherIcon: <LockOutlined className="muted" />,
//     // //     },
//     // //   ]
//     // // },
//     // {
//     //   title: <Space><Typography.Text type="secondary">Blog</Typography.Text><Tag hidden>Coming Soon</Tag></Space>,
//     //   key: 'product-1001-promote-content',
//     //   path: '/business/ai-product/1001/blog',
//     //   icon: <CarryOutOutlined />,
//     //   switcherIcon: <ClockCircleOutlined className="muted" />,
//     //   // children: [
//     //   //   {
//     //   //     title: 'Outline',
//     //   //     key: 'product-1001-promote-content-outline',
//     //   //     icon: <CarryOutOutlined />,
//     //   //     switcherIcon: <LockOutlined className="muted" />,
//     //   //   },
//     //   //   {
//     //   //     title: 'Post',
//     //   //     key: 'product-1001-promote-content-post',
//     //   //     icon: <CarryOutOutlined />,
//     //   //     switcherIcon: <LockOutlined className="muted" />,
//     //   //   },
//     //   // ]
//     // },
//     // {
//     //   title: 'Marketing Plan',
//     //   key: 'product-1001-marketing-plan',
//     //   icon: <CarryOutOutlined />,
//     //   switcherIcon: <LockOutlined className="muted" />,
//     //   children: [
//     //     {
//     //       title: 'SEO',
//     //       key: 'product-1001-marketing-plan-seo',
//     //       icon: <CarryOutOutlined />,
//     //       switcherIcon: <LockOutlined className="muted" />,
//     //     },
//     //     {
//     //       title: 'Launch',
//     //       key: 'product-1001-marketing-plan-launch',
//     //       icon: <CarryOutOutlined />,
//     //       switcherIcon: <LockOutlined className="muted" />,
//     //     },
//     //   ]
//     // },
//     // {
//     //   title: 'Promote',
//     //   key: 'product-1001-promote',
//     //   icon: <CarryOutOutlined />,
//     //   switcherIcon: <LockOutlined className="muted" />,
//     //   children: [
//     //     {
//     //       title: 'Email',
//     //       key: 'product-1001-promote-email',
//     //       icon: <CarryOutOutlined />,
//     //       switcherIcon: <LockOutlined className="muted" />,
//     //     },
//     //   ]
//     // },
//     // {
//     //   title: 'Sell',
//     //   key: 'product-1001-sell',
//     //   switcherIcon: <LockOutlined className="muted" />,
//     //   children: [
//     //     {
//     //       title: 'Amazon',
//     //       key: 'product-1001-sell-amazon',
//     //       icon: <CarryOutOutlined />,
//     //       switcherIcon: <LockOutlined className="muted" />,
//     //     },
//     //     {
//     //       title: 'Instagram',
//     //       key: 'product-1001-sell-instagram',
//     //       icon: <CarryOutOutlined />,
//     //       switcherIcon: <LockOutlined className="muted" />,
//     //     },
//     //     {
//     //       title: 'Facebook',
//     //       key: 'product-1001-sell-facebook',
//     //       icon: <CarryOutOutlined />,
//     //       switcherIcon: <LockOutlined className="muted" />,
//     //     },
//     //     {
//     //       title: 'Jumia',
//     //       key: 'product-1001-sell-jumia',
//     //       icon: <CarryOutOutlined />,
//     //       switcherIcon: <LockOutlined className="muted" />,
//     //     },
//     //     {
//     //       title: 'Etsy',
//     //       key: 'product-1001-sell-etsy',
//     //       icon: <CarryOutOutlined />,
//     //       switcherIcon: <LockOutlined className="muted" />,
//     //     },
//     //     {
//     //       title: 'Local',
//     //       key: 'product-1001-sell-local',
//     //       icon: <CarryOutOutlined />,
//     //       switcherIcon: <LockOutlined className="muted" />,
//     //     },
//     //   ]
//     // },
//     // {
//     //   title: 'Ads',
//     //   key: 'product-1001-ads',
//     //   switcherIcon: <LockOutlined className="muted" />,
//     //   children: [
//     //     {
//     //       title: 'Google',
//     //       key: 'product-1001-ads-google',
//     //       icon: <CarryOutOutlined />,
//     //       switcherIcon: <LockOutlined className="muted" />,
//     //     },
//     //     {
//     //       title: 'Amazon',
//     //       key: 'product-1001-ads-amazon',
//     //       icon: <CarryOutOutlined />,
//     //       switcherIcon: <LockOutlined className="muted" />,
//     //     },
//     //     {
//     //       title: 'Instagram',
//     //       key: 'product-1001-ads-instagram',
//     //       icon: <CarryOutOutlined />,
//     //       switcherIcon: <LockOutlined className="muted" />,
//     //     },
//     //     {
//     //       title: 'Facebook',
//     //       key: 'product-1001-ads-facebook',
//     //       icon: <CarryOutOutlined />,
//     //       switcherIcon: <LockOutlined className="muted" />,
//     //     },
//     //     {
//     //       title: 'Tiktok',
//     //       key: 'product-1001-ads-tiktok',
//     //       icon: <CarryOutOutlined />,
//     //       switcherIcon: <LockOutlined className="muted" />,
//     //     },
//     //     {
//     //       title: 'Twitter',
//     //       key: 'product-1001-ads-twitter',
//     //       icon: <CarryOutOutlined />,
//     //       switcherIcon: <LockOutlined className="muted" />,
//     //     },
//     //   ]
//     // },
//   // ]
// },
// {
//   title: 'FAQ',
//   key: 'product-1001-faq',
//   icon: <CarryOutOutlined />,
//   children: [
//     {
//       title: 'Customer FAQ',
//       key: 'product-1001-faq-customer',
//       icon: <CarryOutOutlined />,
//       switcherIcon: <LockOutlined className="muted" />,
//     },
//     {
//       title: 'Employee FAQ',
//       key: 'product-1001-faq-employee',
//       icon: <CarryOutOutlined />,
//       switcherIcon: <LockOutlined className="muted" />,
//     },
//   ]
// },
// children: [
//   {
//     title: 'Outline',
//     key: 'product-1001-promote-content-outline',
//     icon: <CarryOutOutlined />,
//     switcherIcon: <LockOutlined className="muted" />,
//   },
//   {
//     title: 'Post',
//     key: 'product-1001-promote-content-post',
//     icon: <CarryOutOutlined />,
//     switcherIcon: <LockOutlined className="muted" />,
//   },
// ]
// {
//   title: 'Add Role',
//   key: 'team-hire-add-role',
//   icon: <CarryOutOutlined />,
//   switcherIcon: <LockOutlined className="muted" />,
// },
// {
//   title: 'Employees',
//   key: 'team-employees',
//   switcherIcon: <LockOutlined className="muted" />,
//   children: [
//     {
//       title: 'Add',
//       key: 'team-employee-add',
//       icon: <CarryOutOutlined />,
//       switcherIcon: <LockOutlined className="muted" />,
//     },
//     {
//       title: 'List',
//       key: 'team-employee-list',
//       icon: <CarryOutOutlined />,
//       switcherIcon: <LockOutlined className="muted" />,
//     },
//   ],
// },
