import React from 'react'
import {Divider, Space} from 'antd'
import {Link} from 'wouter'

export default function LoginLinks({appName}) {
  return (
    <Space>
      <Link href="/about">Why we built {appName}</Link>
      <Divider orientation="vertical" />
      <a href="/legal/terms.html">Terms</a>
      <Divider orientation="vertical" />
      <Link href="/about/faq">FAQ</Link>
    </Space>
  )
}