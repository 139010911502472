import React, {useContext} from 'react'
import {Button, Carousel} from 'antd'
import {ga} from '@web/lib/firebase'
import {AppContext, WindowContext} from '@web/hooks/context'
import SplashCard from './SplashCard'
import {LeftArrowOutlined, RightArrowOutlined} from '@web/assets/icons'

export const Splash = () => {
  const [app] = useContext(AppContext)
  const [isMobile] = useContext(WindowContext)
  let slider
  const navigateNext = e => {
    slider.next()
    ga.click(e)
  }
  return (
    <Carousel
      ref={sliderRef => slider = sliderRef}
      className="splash-carousel-dots"
      adaptiveHeight
      prevArrow={!isMobile && <Button icon={<LeftArrowOutlined />} />}
      nextArrow={!isMobile && <Button icon={<RightArrowOutlined />} />}
      arrows={!isMobile}
    >
      {
        app?.home?.splash?.show &&
        app?.home?.splash?.cards?.map((card, index) => ((!card.hide &&
          <SplashCard.Home
            key={`home-splash-${index}`}
            card={card}
            navigateNext={navigateNext}
          />) ||
          null
          ))
      }
      {
        app?.workspace?.promote?.reduce((accumulator, agentId) => {
          if (app?.agents[agentId]?.features?.length) accumulator.push(
            <SplashCard
              key={agentId}
              agentId={agentId}
              agent={app?.agents?.[agentId]}
            />
          )
          return accumulator
        }, [])
      }
      <SplashCard.Summary
        agents={app.agents}
        workspace={app.workspace}
      />
    </Carousel>
  )
}
