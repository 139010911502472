import React from 'react'
import PropTypes from 'prop-types'

MoreDetails.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
}
const bodyStyle = {paddingTop: '8px'}
function MoreDetails({text, title = 'More info', children}) {
  return (
    <details>
      <summary>{title}</summary>
      <div style={bodyStyle}>{children || text}</div>
    </details>
  );
}

export default MoreDetails;