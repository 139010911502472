import React, {useState} from 'react'
import {Button, Popover, Space, message} from 'antd'
import {
  DeleteOutlined,
  DislikeFilled,
  DislikeOutlined,
  LikeFilled,
  LikeOutlined
} from '@ant-design/icons'
import {deleteDocument, updateDocument} from '@web/lib/firestore.db'

export default function ContentActions({children, placement, content, contentId, collection, saveAction, rerunAction, agentActions}) {
  const [open, setOpen] = useState(false)
  const onLike = like => updateDocument(collection, contentId, {like})
    .then(() => message.info(like ? 'Liked' : 'Disliked'))
    .catch(() => message.error('Try again later'))
  const onClickDelete = () => {
    return deleteDocument(collection, contentId)
      .then(() => message.success('Deleted'))
      .catch(() => message.error('Oops, unable to delete'))
  }
  const handleOpenChange = open => setOpen(open)
  return (
    <Popover
      content={
        <div className="space space-between action-strip">
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={onClickDelete}
          />
          <Space>
            <Button
              icon={content?.like === false ? <DislikeFilled /> : <DislikeOutlined />}
              onClick={() => onLike(false)}
            />
            <Button
              icon={content?.like === true ? <LikeFilled /> : <LikeOutlined />}
              onClick={() => onLike(true)}
            />
            {saveAction}
            {rerunAction}
            {agentActions}
          </Space>
        </div>
      }
      title={null}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      placement={placement || "top"}
    >
      {children}
    </Popover>
  )
}