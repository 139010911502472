import React, {useContext} from 'react'
import {Button, Divider, Layout, Space, Tag, Typography} from 'antd'
import DesktopSidebar from '@ai-antd/components/DesktopSidebar'
import Logo from '@ai-antd/components/Logo'
import useWindowSize from '@web/hooks/useWindowSize'
import {AppContext, WindowContext} from '@web/hooks/context'
import {isInStandaloneMode} from '@web/lib/app'
import TreeMenu from '@ai-antd/components/TreeMenu'
import {TwitterOutlined} from '@ant-design/icons'
import {Link, Route, Switch} from 'wouter'
import Landing from './Landing'
import Dashboard from './Dashboard'
import {UserOutlined} from '@web/assets/icons'

const { Content } = Layout

export default function BizLayout({name, domain, children}) {
  const { width, height, isMobile } = useWindowSize()
  const [{root = {}, admin = {}}, user] = useContext(AppContext)
  const isStandalone = isInStandaloneMode()
  const screen = [isMobile, width, height, isStandalone]
  const layoutClassName = isMobile ? 'mobileLayout' : 'desktopLayout'
  const innerLayoutClassName = isMobile ? 'innerMobileLayout' : 'innerDesktopLayout'
  const contentClassName = isMobile ? 'mobileContent' : 'desktopContent'
  // const pwaProps = isStandalone ? {onBack: () => window.history.back()} : {}
  const tags = root.isBeta ?
    [<Tag key="beta">Beta</Tag>] :
    null
  return <WindowContext.Provider value={screen}>
    <Layout className={layoutClassName} style={{width}}>
      <Layout className="app-content-layout">
        {!isMobile && <DesktopSidebar className="ai-biz-sidebar">
          <Logo name={name} domain={domain} tags={tags} />
          <Divider className="small" />
          <TreeMenu />
          <Space direction="vertical">
            <Link href="/account"><Typography.Text className="cursor-pointer">Account</Typography.Text></Link>
            {admin.enable && <Link href="/admin"><Typography.Text className="cursor-pointer">Admin</Typography.Text></Link>}
          </Space>
        </DesktopSidebar>}
        <Layout className={innerLayoutClassName}>
          <Content className={contentClassName}>
            {isMobile && user && !user.isAnonymous && <div>
              <div className="space space-center-y space-between">
                <Link href="/account"><div><UserOutlined /></div></Link>
                <div><Logo name={name} domain={domain} tags={tags} /></div>
                <a target="_blank" href="https://twitter.com/emomoh">
                  <Button ghost size="large" icon={<TwitterOutlined style={{fontSize: 24}} />} />
                </a>
              </div>
              <Divider className="small-top" />
              <TreeMenu />
            </div>}
            {isMobile && (!user || user?.isAnonymous) && <Landing />}
            {!isMobile && <Switch>
              <Route path="/">
                <Dashboard />
              </Route>
            </Switch>}
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  </WindowContext.Provider>
}

