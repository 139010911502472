import React from 'react'
import {FiChevronLeft, FiUpload, FiBriefcase, FiChevronRight, FiChevronDown, FiUser, FiHome, FiMessageSquare, FiInfo, FiLink2, FiX} from 'react-icons/fi'
import {BsFillBookmarkFill, BsCoin, BsHeartFill} from 'react-icons/bs'
import {BiBrain, BiUpArrowAlt} from 'react-icons/bi'
export const LeftArrowOutlined = ({size = 24}) => <FiChevronLeft size={size} />
export const RightArrowOutlined = ({size = 24}) => <FiChevronRight size={size} />
export const DownArrowOutlined = ({size = 24}) => <FiChevronDown size={size} />
export const UserOutlined = ({size = 24}) => <FiUser size={size} />
export const HomeOutlined = ({size = 24}) => <FiHome size={size} />
export const MessageOutlined = ({size = 24}) => <FiMessageSquare size={size} />
export const InfoOutlined = ({size = 24}) => <FiInfo size={size} />
export const LinkOutlined = ({size = 24}) => <FiLink2 size={size} />
export const Brainstorm = ({size = 24}) => <BiBrain size={size} />
export const SendArrow = ({size = 24}) => <BiUpArrowAlt size={size} />
export const SavedFilled = ({size = 24}) => <BsFillBookmarkFill size={size} />
export const HeartIconFilled = ({size = 24}) => <BsHeartFill size={size} />
export const XIcon = ({size = 24}) => <FiX size={size} />
export const UploadIcon = ({size = 24}) => <FiUpload size={size} />
export const TokenOutlined = ({size = 24}) => <BsCoin size={size} />
export const KitOutlined = ({size = 24}) => <FiBriefcase size={size} />
export const SolutionOutlined = ({size = 24}) => null
