import React, {useContext} from 'react'
import {Col, Row, Space, Typography} from 'antd'
import News from '@ai-antd/components/News'
import {WindowContext} from '@web/hooks/context'
import LatestActivity from '@ai-antd/components/LatestActivity'
import Posts from '@ai-antd/components/Posts'
import LoginLinkButton from '@ai-antd/components/LoginLinkButton'

const Dashboard = () => {
  const [isMobile] = useContext(WindowContext)
  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={18} xl={16} xxl={12}>
          <Space direction="vertical" style={{width: '100%'}}>
            <div className="space space-between">
              <Typography.Title level={5}>Latest Activity</Typography.Title>
              {!isMobile && <LoginLinkButton>Login to Continue</LoginLinkButton>}
            </div>
            <LatestActivity count={1} />
            {!isMobile && <News/>}
          </Space>
        </Col>
        <Col xs={0} sm={0} md={0} lg={6} xl={8} xxl={12}>
          <News />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={18} xl={16} xxl={12}>
          <br/><br/>
          <Typography.Title level={5}>Learn</Typography.Title>
          <Posts />
        </Col>
      </Row>
    </div>
  )
}

export default Dashboard
