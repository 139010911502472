import React, {useContext, useState} from 'react';
import {WindowContext} from '@web/hooks/context'
import {Divider, Drawer, Typography} from 'antd'
import {useLocation} from 'wouter'
import PageDrawerBackButton from '@ai-antd/components/PageDrawerBackButton'
import FeedbackModal from '@ai-antd/components/FeedbackModal'
import {notifyAndTrack} from '@ai-antd/components/notification'

const headerStyle = {backgroundColor: '#000'}
const drawerTransparent = {backgroundColor: 'transparent'}

PageDrawer.propTypes = {

};

function PageDrawer(props) {
  const [isMobile, width] = useContext(WindowContext)
  const [show, setShow] = useState(true)
  const [, setLocation] = useLocation()
  // console.log('PageDrawer', isMobile, width)
  const onClose = () => {
    setShow(false)
  }
  const updateLocation = (open) => {
    !open && setLocation('/')
  }
  const onFeedback = (submitted) => {
    submitted && notifyAndTrack.success('Feedback received. Thank you!')
  }
  if (!isMobile) return <>
    {props.title &&
      <div style={{paddingBottom: '16px'}}><Typography.Title level={4}>{props.title}</Typography.Title>
        <Divider className="small" />
      </div> || null
    }
    {props.children}
  </>
  return (
    <Drawer
      width={width}
      className="page-drawer"
      title={<PageDrawerBackButton onClick={onClose} />}
      destroyOnClose={false}
      open={show}
      onClose={onClose}
      placement="right"
      closable={false}
      headerStyle={headerStyle}
      drawerStyle={drawerTransparent}
      contentWrapperStyle={drawerTransparent}
      afterOpenChange={updateLocation}
      extra={<FeedbackModal onClose={onFeedback} />}
    >{props.title && <div style={{paddingBottom: '16px'}}><Typography.Title level={4}>{props.title}</Typography.Title></div> || null}
      {props.children}
    </Drawer>
  );
}

export default PageDrawer;
