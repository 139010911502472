import {buildQuery, colls, getDocument, getDocuments} from './firestore.db'
import {asyncReduce} from './promise'
import {collection, getDocs, query} from 'firebase/firestore'
import {firestore} from './firebase'

export const getProductPrices = async (productKeys) => {
  const querySnapshots = await getDocuments(
    buildQuery(colls['products-stripe'], {
      '==': {active: true},
      'in': {role: productKeys},
    }))
  return await asyncReduce(querySnapshots.docs, async (accumulator, doc) => {
    const product = doc.data()
    product.prices = {}
    const pricesQuerySnapshot = await getDocs(
      query(collection(firestore, `products-stripe/${doc.id}/prices`))
    )
    pricesQuerySnapshot.docs.forEach(doc =>
      product.prices[doc.get('interval')] = [doc.id, doc.data()]
    )
    return accumulator.push([doc.id, product]) && accumulator
  }, [])
}
export const getProductPrice = async (productId) => {
  const doc = await getDocument(colls['products-stripe'], productId)
  const product = doc.data()
  product.prices = {}
  const pricesQuerySnapshot = await getDocs(
    query(collection(firestore, `products-stripe/${doc.id}/prices`))
  )
  pricesQuerySnapshot.docs.forEach(doc =>
    product.prices[doc.get('interval')] = [doc.id, doc.data()]
  )
  return product
}