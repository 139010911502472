import React from 'react'
import {Button, Collapse, Divider, Space, Typography} from 'antd'
import {Link} from 'wouter'
import Icon from './Icon'

const FeaturesList = ({agents = {}, agentIds = []}) => {
  return (
    <Collapse accordion expandIconPosition="end" ghost bordered={false}>
      {agentIds.reduce((accumulator, agentId) => {
        const {description, name, icon, features = [], demo = {}} = agents[agentId]
        accumulator.push(
          <Collapse.Panel header={<Space><Icon name={icon} /><Typography>{name}</Typography></Space>} key={agentId}>
            <Typography.Paragraph>{description}</Typography.Paragraph>
            <Space>
              {demo && !demo.hidden &&
                <Link to={`/demo/${agentId}`}>
                  <Button type="primary">Demo</Button>
                </Link>
              }
              <Link to="/sign-up">
                <Button>Get Started Now</Button>
              </Link>
            </Space>
            <Divider className="small" />
            <ul>
              {features.map(({actionVerb, feature}) => {
                return <li key={actionVerb}><p>{feature}</p></li>
              })}
            </ul>
          </Collapse.Panel>)
        return accumulator
        }, [])
      }
    </Collapse>
  )
}

export default FeaturesList
