import React from 'react';
import PropTypes from 'prop-types';
import {LeftArrowOutlined} from '@web/assets/icons'
import {Button, Space, Typography} from 'antd'

PageDrawerBackButton.propTypes = {
  onClick: PropTypes.func.isRequired
};
const buttonStyle = {position: 'relative', left: '-12px'}
function PageDrawerBackButton(props) {
  return (
    <div>
      <Space style={buttonStyle} onClick={props.onClick}>
        <Button ghost icon={<LeftArrowOutlined/>} />
        {!props.hideText && <Typography>Back</Typography>}
      </Space>
    </div>
  )
}

export default PageDrawerBackButton;