import {collection, getDoc, doc, getDocs, limit, orderBy, query, where, updateDoc, addDoc, setDoc, deleteDoc, increment as incr, deleteField, arrayUnion, arrayRemove} from 'firebase/firestore'
import {firestore} from './firebase'
import now from 'lodash/now'
import reduce from 'lodash/reduce'
import flatten from 'lodash/flatten'
import isArray from 'lodash/isArray'
export const colls = {
  'agent-config': 'agent-config',
  'ai-agents': 'ai-agents',
  'ai-agents-list': 'ai-agents-list',
  'ai-article': 'ai-article',
  'ai-augment': 'ai-augment',
  'ai-chat': 'ai-chat',
  'ai-chat-log': 'ai-chat-log',
  'ai-workspace': 'ai-workspace',
  'amazon-lists': 'amazon-lists',
  'app-faq': 'app-faq',
  'art-image': 'art-image',
  'business-profile': 'business-profile',
  'customers-stripe': 'customers-stripe',
  'openai-brainstorm': 'openai-brainstorm',
  'openai-content': 'openai-content',
  'openai-models': 'openai-models',
  'oven-apps': 'oven-apps',
  'oven-stripe-log': 'oven-stripe-log',
  'post': 'post',
  'products-stripe': 'products-stripe',
  'link-listings': 'link-listings',
  'looks': 'looks',
  'looks-swipe': 'looks-swipe',
  'stage': 'stage',
  'user-audience': 'user-audience',
  'user-feedback': 'user-feedback',
  'user-interests': 'user-interests',
  'user-tips': 'user-tips',
  'wait-list': 'wait-list',
}
export const increment = incr
/**
 * @param {[]} querySnapshot
 */
export const toDataPairs = (querySnapshot) => {
  if (!querySnapshot?.size) return []
  const pairs = []
  querySnapshot.size && querySnapshot.docs.forEach(snapshot => pairs.push([snapshot.id, snapshot.data()]))
  return pairs
}
export const generateID = () => doc(collection(firestore, 'dummy')).id

/**
 * @param rawJson {object} - raw json object
 * @returns {{length}|*|null|{}|null} - json object with all null values removed
 */
export const safeValueForDB = (rawJson) => {
  switch (true) {
    case (rawJson == null): {
      return {};
    }
    case isArray(rawJson): {
      const array = rawJson.reduce((accumulator, item) => {
        if (item && item !== undefined) accumulator.push(safeValueForDB(item));
        return accumulator;
      }, []);
      return array.length ? array : null;
    }
    case (typeof rawJson === "object"): {
      const map = {};
      Object.keys(rawJson).forEach((key) => {
        if (key && rawJson[key] !== undefined && rawJson[key] != null) {
          map[key] = safeValueForDB(rawJson[key]);
        }
      });
      return map;
    }
    default: {
      if (rawJson === undefined) rawJson = null;
      return rawJson;
    }
  }
}
/**
 *
 * @param collectionName {string} - name of the collection
 * @param fields {object} - fields to query by (e.g. {name: 'John'})
 * @param queryLimit {number} - limit the number of results
 * @param order {[]} - order by (e.g. [['name', 'asc']])
 * @returns {Query<DocumentData>} - firestore query object
 */
export const buildQuery = (collectionName, fields, {limit: queryLimit = 10, order = []} = {}) => {
  if (!colls[collectionName]) throw Error(`Collection name "${collectionName}" is not valid.`)
  // console.log('query', flatten(Object.keys(fields).map(indicator => {
  //   return reduce(fields[indicator], (predicates, value, fieldName) => {
  //     predicates.push(where(fieldName, indicator, value))
  //     return predicates
  //   }, [])
  // })))
  return query(
    collection(firestore, collectionName),
    ...flatten(Object.keys(fields).map(indicator => {
      return reduce(fields[indicator], (predicates, value, fieldName) => {
        predicates.push(where(fieldName, indicator, value))
        return predicates
      }, [])
    })),
    ...order.map(([field, direction = 'asc']) => orderBy(field, direction)),
    limit(queryLimit)
  )
}
/**
 * @param {string} collectionName - name of the collection
 * @param documentId {string} - document id to get from collection
 */
const getDocumentRef = (collectionName, documentId) => {
  if (!documentId) throw new Error(`documentID is required`)
  if (Object.values(colls).indexOf(collectionName) === -1) throw new Error(`"${collectionName}" not found in colls map`)
  return doc(firestore, `${collectionName}/${documentId}`)
}
export const getDocuments = query => getDocs(query)
export const getCollection = collectionName => {
  if (!collectionName) throw new Error(`CollectionName is required`)
  if (Object.values(colls).indexOf(collectionName) === -1) throw new Error(`"${collectionName}" not found in colls map`)
  return collection(firestore, collectionName)
}
export const getDocument = async (collectionName, documentId) => await getDoc(getDocumentRef(collectionName, documentId))
export const updateDocument = async (collectionName, documentId, data) => {
  const ref = getDocumentRef(collectionName, documentId)
  return !!ref && await updateDoc(ref, data)
}
export const addDocument = async (collectionName, data) => {
  const collection = getCollection(collectionName)
  return !!data && !!collection && await addDoc(collection, {
    ...data, createdTs: now(), updatedTs: now(),
  })
}
export const addDocument_NoTimestamp = async (collectionName, data) => {
  const collection = getCollection(collectionName)
  return !!data && !!collection && await addDoc(collection, data)
}
export const setDocument = async (collectionName, documentId, data) => {
  const ref = getDocumentRef(collectionName, documentId)
  return !!ref && await setDoc(ref, data)
}
export const deleteDocument = async (collectionName, documentId) => {
  const ref = getDocumentRef(collectionName, documentId)
  return !!ref && await deleteDoc(ref)
}
export const Fields = {
  deleteField,
  arrayUnion,
  arrayRemove,
}
