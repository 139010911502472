import React from 'react'
import {Carousel, Divider} from 'antd'

export default function News() {
  const news = []
  if (!news.length) return null
  return <>
    <Divider orientation="left">News</Divider>
    <Carousel centerMode autoplay={false} />
  </>
}