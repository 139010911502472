import React, {useContext, useEffect, useState} from 'react'
import {Select, Tag, Typography} from 'antd'
import {AppContext} from '@web/hooks/context'
import useLocalStorage from '@web/hooks/useLocalStorage'

const localStorageKey = 'chatAgentId'
const selectStyle = { minWidth: 260 }
export default function AgentSelector(props) {
  const [{chat = {}, agents = {}}] = useContext(AppContext)
  const [savedAgentId, saveAgentId] = useLocalStorage(localStorageKey)
  const [agentId, setAgentId] = useState(savedAgentId || chat?.agentIds?.[0])
  const agentItems = chat.enable &&
    chat.agentIds?.reduce((items, agentId) => {
      if (agents[agentId]) {
        items.push({
          value: agentId,
          label: <Typography.Title
            className="inline-title p-6"
            level={5}
          >
            <Tag size="small">Ai</Tag>
            {agents[agentId].shortName || agents[agentId].name || agents[agentId].role}
          </Typography.Title>,
        })
      }
      return items
    }, []) || []
  if (!agentItems.length) return null
  const onSelect = agentId => setAgentId(agentId)
  useEffect(() => {
    props.onSelect(agentId)
    saveAgentId(agentId)
  }, [agentId])
  return <Select
    size="large"
    className="agent-selector"
    style={selectStyle}
    dropdownMatchSelectWidth={false}
    placement="bottomRight"
    onSelect={onSelect}
    defaultValue={agentId}
    value={agentId}
    options={agentItems}
    bordered={false}
    listHeight={400}
  />
}