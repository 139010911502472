import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {ProgressBar} from './ProgressBar'
import {AppContext} from '@web/hooks/context'
import {Button, Card, Space, Typography} from 'antd'
import {saveToStorage} from '@web/hooks/useLocalStorage'
import {ga} from '@web/lib/firebase'
import {useLocation} from 'wouter'
import useStripeSubscriptionCheckout from '@web/hooks/useStripeSubscriptionCheckout'
import {notifyAndTrack} from './notification'
import {getProductPrice} from '@web/lib/stripe.db'
import Loading from './Loading'

PriceCard.propTypes = {
  productId: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
};

function PriceCard({productId, onSelect, selected}) {
  const [app, user] = useContext(AppContext)
  const [location, setLocation] = useLocation()
  const [loading, setLoading] = useState(true)
  const [pricingInterval, setPricingInterval] = useState('month')
  const data = useRef({})
  const [checkoutUrl, loadingCheckoutUrl, errorCheckoutUrl, requestStripeCheckoutUrl, stopProcessing] = useStripeSubscriptionCheckout()
  useEffect(() => {
    errorCheckoutUrl && handleError(errorCheckoutUrl)
  }, [errorCheckoutUrl])

  useEffect(() => {
    if (!productId) return setLoading(false)
    getProductPrice(productId)
      .then((product = {}) => {
        data.current = product
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [productId])

  if (loading) return <Loading />
  if (checkoutUrl) window.location.assign(checkoutUrl)
  const handleError = (error) => {
    stopProcessing()
    console.log('error', error, error.code)
    notifyAndTrack.info({
      severity: 'critical',
      message: 'Oops, something didn\'t work .',
      description: error.message,
      priceId: selected,
      app: app?.root?.key,
    })
  }
  const startTrial = (priceId, role) => {
    if (!user || user?.isAnonymous) {
      saveToStorage('product-selection', {priceId, role})
      return setLocation('/sign-up')
    }
    const {options = {}} = overrides || {}
    const checkoutData = {
      userId: user.uid,
      priceId,
      successUrl: window.location.href,
      cancelUrl: window.location.href,
      allowTrial: true,
      allowPromoCode: true,
      applyPromoCode: null,
      collectShippingAddress: false,
      ...options,
    }
    ga.log('payment_checkout', checkoutData)
    onSelect(priceId)
    requestStripeCheckoutUrl(checkoutData)
  }
  console.log('PriceCard', productId, data.current)
  const overrides = app.products?.items?.[productId] || {}
  return <Card title={<Typography.Title style={{paddingLeft: '20px'}} level={4}>{overrides.title || data.current.name}</Typography.Title>} bordered>
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '340px', padding: '0 20px'}}>
      <Space direction="vertical" >
        {overrides.subtitle && <Typography.Title level={5}>{overrides.subtitle}</Typography.Title>}
      </Space>
      <Typography.Paragraph type="secondary">{overrides.description || data.current.description}</Typography.Paragraph>
      <div>
        <Space>
          <Typography.Title level={2}>${data.current?.prices?.[pricingInterval]?.[1]?.unit_amount / 100}</Typography.Title>
          <Typography className="muted">per {pricingInterval}</Typography>
        </Space>
        <Button
          block
          type="primary"
          size="large"
          disabled={!!(loadingCheckoutUrl && loadingCheckoutUrl === data.current?.prices?.[pricingInterval]?.[0])}
          onClick={() => startTrial(data.current?.prices?.[pricingInterval]?.[0])}
        >
          {loadingCheckoutUrl && loadingCheckoutUrl === data.current?.prices?.[pricingInterval]?.[0] ?
            <ProgressBar visible float={false} /> :
            overrides.cta || 'Start free trial'
          }
        </Button>
      </div>
    </div>
  </Card>
}

export default PriceCard;