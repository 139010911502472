import React from 'react'
import {useLocation} from 'wouter'
import {Button} from 'antd'

export const SignUpButton = ({size = 'large', type = 'secondary', block = true, text = 'Sign Up'}) => {
  const [, setLocation] = useLocation()
  const onClickSignUp = () => setLocation('/sign-up')
  return (
    <Button
      block={block}
      size={size}
      type={type}
      onClick={onClickSignUp}
    >
      {text}
    </Button>
  )
}