import React, {useContext, useState} from 'react'
import {Alert, Button, Drawer} from 'antd'
import {Brainstorm, LeftArrowOutlined, RightArrowOutlined} from '@web/assets/icons'
import {WindowContext, ChatContext, AppContext} from '@web/hooks/context'
import Chat from './Chat'
import AgentSelector from './AgentSelector'
import Icon from './Icon'
import PageDrawerBackButton from './PageDrawerBackButton'
import {useLocation} from 'wouter'
const iconStyle = {textAlign: 'right'}
const headerStyle = {backgroundColor: '#000'}
const drawerTransparent = {backgroundColor: 'transparent'}
const ChatDrawer = (props) => {
  const [isMobile, width] = useContext(WindowContext)
  const [{chat = {}, agents = {}}] = useContext(AppContext)
  const [agentId, setAgentId] = useState()
  const [show, setShow] = useState(!!props.show)
  const [location, setLocation] = useLocation()
  if (!chat.enable) return null
  const onClickShow = () => setShow(!show)
  const onClose = () => setShow(false)
  const onSelectAgent = setAgentId
  const updateLocation = (open) => {
    !open && location.startsWith('/chat') && setLocation('/')
  }
  return (
    <>
      {!props.controlled && <Button
        style={iconStyle}
        size="large"
        icon={
          chat.icon ?
            <Icon name={chat.icon} size={26}/> :
            <Brainstorm size={26}/>
        }
        onClick={onClickShow}
        ghost
      />}
      <Drawer
        width={isMobile ? width : 390}
        className="brainstorm-drawer"
        title={
        props.controlled ?
          <PageDrawerBackButton onClick={onClose} hideText /> :
          <Button
          ghost icon={isMobile ? <LeftArrowOutlined /> : <RightArrowOutlined />}
          onClick={onClose}
        />
      }
        afterOpenChange={updateLocation}
        extra={<AgentSelector onSelect={onSelectAgent} />}
        destroyOnClose={false}
        open={show}
        onClose={onClose}
        placement="right"
        closable={false}
        headerStyle={headerStyle}
        drawerStyle={drawerTransparent}
        contentWrapperStyle={drawerTransparent}
      >
        {agentId && agents[agentId] ?
          <ChatContext.Provider value={[agentId, agents[agentId]]}>
            <Chat />
          </ChatContext.Provider> :
          <Alert
            message="Chat is currently unavailable"
            description="Check back later."
            type="info"
            showIcon
          />
        }
      </Drawer>
    </>
  )
}

export default ChatDrawer
