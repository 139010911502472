import React, {useState, useRef, useContext} from 'react'
import PropTypes from 'prop-types'
import {Tree, Divider} from 'antd'
import {AppContext, WindowContext} from '@web/hooks/context'
import without from 'lodash/without'
import NavMenuItem from '@ai-antd/components/NavMenuItem'
import {useLocation} from 'wouter'

const showLine = {showLeafIcon: false, showLine: true}
TreeMenu.propTypes = {
  onNav: PropTypes.func,
  className: PropTypes.string,
}

function TreeMenu(props) {
  const [showIcon] = useState(false)
  const [isMobile] = useContext(WindowContext)
  const [,,, treeData = []] = useContext(AppContext)
  const [expandedKeys = [], setExpandedKeys] = useState(['start', 'basic', 'business', 'products'])
  const [location, setLocation] = useLocation()
  const tree = useRef()
  const onClick = (e, node) => {
    if (node?.children?.length) {
      const newKeys = [...expandedKeys]
      setExpandedKeys(node.expanded ? without(expandedKeys, node.key) : newKeys.push(node.key) && newKeys)
    }
    if (!node?.path) return
    setLocation(node.path)
    props.onNav && props.onNav(node)
  }
  const onFilterTreeNode = (node) => {
    return node.key === 'business'
  }
  const onRenderTitle = (node) => {
    // console.log('onRenderTitle', node)
    return <NavMenuItem
      inactive={node.inactive}
      selected={node.path === location}
      text={node.title}
      isNew={node.isNew}
    />
  }
  const onExpand = (expandedKeys, node) => {
    setExpandedKeys(expandedKeys)
  }
  // const scrollTo = key => tree.current.scrollTo({ key })
  return <div className="navbar-padding">
    <Tree
      ref={tree}
      expandedKeys={expandedKeys}
      selectable={false}
      onClick={onClick}
      showLine={showLine}
      showIcon={showIcon}
      treeData={treeData}
      onExpand={onExpand}
      filterTreeNode={onFilterTreeNode}
      titleRender={onRenderTitle}
      rootClassName={`vision-tree ${isMobile ? 'mobile' : 'not-mobile'} ${props.className || ''}`}
    />
    <Divider />
  </div>
}

export default TreeMenu
