import React from 'react'
import {Typography} from 'antd'
import {colls} from '@web/lib/firestore.db'
import ContentActions from './ContentActions'

export default function BrainstormPrompt({messageId, prompt = {}}) {
  return (
    <ContentActions
      contentId={messageId}
      content={prompt}
      collection={colls["ai-chat"]}
    >
      <li className="brainstorm-message right">
        {
          <Typography.Paragraph copyable={false}>
            <p className="content-text">{prompt?.text}</p>
          </Typography.Paragraph>
        }
      </li>
  </ContentActions>
  )
}