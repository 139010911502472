import React, {useContext} from 'react'
import {Splash} from '@ai-antd/components/Splash'
import Logo from '@ai-antd/components/Logo'
import {PageHeader, Tag} from 'antd'
import {AppContext, WindowContext} from '@web/hooks/context'
export default function Landing() {
  const [app] = useContext(AppContext)
  const [isMobile] = useContext(WindowContext)
  return <div>
    <PageHeader
      tags={[]}
      title={isMobile &&
        <div className="space space-center">
          <Logo name={app.root.name} domain={app.root.domain} tags={[<Tag key="beta">Beta</Tag>]} />
        </div>
      }
    />
    <Splash />
  </div>
}
