import React ,{useEffect} from 'react'
import {Redirect} from 'wouter'
import {auth} from '@web/lib/firebase'
import {useAuthState} from 'react-firebase-hooks/auth'
import Loading from '@ai-antd/components/Loading'

export default function Logout() {
  const [user, loading] = useAuthState(auth)
  if (loading) return <Loading />
  useEffect(() => {
    !!user && auth.signOut()
  }, [])
  return !user ? <Redirect to="/" /> : <Loading />
}