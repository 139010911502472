import React, {Fragment, useContext} from 'react'
import PropTypes from 'prop-types'
import {Link, useLocation} from 'wouter'
import {Button} from 'antd'
import {AppContext} from '@web/hooks/context'

LoginLinkButton.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  divider: PropTypes.node,
  signup: PropTypes.bool,
};

function LoginLinkButton({type = 'primary', disabled = false, divider = null, signup = false, children}) {
  const [location] = useLocation()
  const [, user = {}] = useContext(AppContext) || []
  if (location === '/login' || location === '/sign-up') return null
  if (user?.uid && !user?.isAnonymous) return null
  const action = signup ? 'Signup' : 'Login'
  return <Fragment><Link href={`/${action.toLowerCase()}`}>
    <Button disabled={disabled} type={type}>{children || action}</Button>
  </Link>
    {divider}
  </Fragment>
}

export default LoginLinkButton
