import React from 'react'
import {LoadingOutlined} from '@ant-design/icons'
const iconSize = {
  small: 24,
  medium: 40,
  large: 60,
}
const Loading = ({size = 'medium'}) => {
  return (
    <div className="loading-wrapper">
      <LoadingOutlined
        style={{fontSize: iconSize[size] || iconSize.small}}
      />
    </div>
  )
}

export default Loading
