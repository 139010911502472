import React, {useContext} from 'react'
import {Alert, List} from 'antd'
import {useAuthState} from 'react-firebase-hooks/auth'
import {auth} from '@web/lib/firebase'
import {buildQuery, colls} from '@web/lib/firestore.db'
import useQueryFirestore from '@web/hooks/useQueryFirestore'
import ChatItem from './ChatItem'
import {ChatContext, WindowContext} from '@web/hooks/context'

const topBarHeight = 65
const getListItem = ([chatId, chat]) => {
  const props = {
    key: chatId,
    chatId,
    chat,
  }
  return <ChatItem {...props} />
}
const ChatThread = () => {
  const [agentId] = useContext(ChatContext)
  const [user] = useAuthState(auth)
  const [pairs = [], dataLoading, error] = useQueryFirestore(
    buildQuery(
      colls['ai-chat'],
      {'==': {
        "prompt.userId": user.uid,
          "prompt.agentId": agentId || "",
        }},
      {order: [['prompt.createdTs', 'desc']], limit: 20})
  )
  const [,, height] = useContext(WindowContext)
  return <section className="brainstorm-thread-wrapper" style={{height: height - topBarHeight}}>
    {!!error && <Alert message="Unable to load content at this time." type="error" />}
    <List
      className="brainstorm-thread"
      split={false}
      hidden={pairs.length === 0}
      dataSource={pairs.reverse()}
      renderItem={getListItem}
      loading={dataLoading}
    />
  </section>
}

export default ChatThread
