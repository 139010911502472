import React, {useState} from 'react'
import {urlFind} from '@web/lib/regex'
import * as api from '@web/lib/callable'
import uniq from 'lodash/uniq'
import {ProgressBar} from '@ai-antd/components/ProgressBar'
const tagMap = {
  'og:description': 'description',
  'twitter:description': 'description',
  'description': 'description',
  'og:title': 'title',
  'twitter:title': 'title',
  title: 'title',
  'og:image:secure_url': 'image',
  'og:image': 'image',
  'og:price:amount': 'price',
  price: 'price',
  'og:price:currency': 'currency',
  currency: 'currency',
  'og:site_name': 'siteName',
  'og:type': 'type',
  'og:url': 'url',
  'al:web:url': 'url',
}
const initializeFields = tagMap => {
  const fields = {}
  uniq(Object.values(tagMap)).forEach(field => fields[field] = [])
  return fields
}
const extractLinkData = metaTags => {
  let fieldsFound = 0
  const data = initializeFields(tagMap);
  Object.keys(tagMap).forEach(tagName => {
    if (metaTags?.[tagName]) {
      data[tagMap[tagName]].push(metaTags?.[tagName]?.content)
      fieldsFound += 1
    }
  })
  return fieldsFound ? data : null
}
const isValidUrl = (urlString, httpsOnly) => {
  let url
  try {
    url = new URL(urlString)
  }
  catch(e){
    return false;
  }
  return httpsOnly ? url.protocol === 'https:' : true
}
export default function LinkParser({text, media = [], ...props}) {
  const [linkData, setLinkData] = useState()
  const [showLinkProgress, setShowLinkProgress] = useState(false)
  const getDescription = linkData => linkData?.description?.[0] || linkData?.title?.[0] || linkData?.siteName?.[0]
  const getMedia = linkData => {
    const clientUrl = linkData?.image?.[0]
    if (clientUrl) return {
      clientUrl
    }
  }
  const processLink = async link => {
    if (!link) return
    if (!isValidUrl(link, true)) {
      props.onError({
        track: 'Invalid attempt to process link',
        link,
        message: 'Invalid Link',
        description: 'The link cannot be used. Please try a different one.'
      })
      return
    }
    const metaTags = await api.getLinkMeta(link)
      .catch(e => {
        props.onError({
          message: 'Unusable Link',
          description: e?.message === 'internal'
            ? 'Please try a different link'
            : e?.message,
        })
      })
    if (!metaTags) return
    return extractLinkData(metaTags.data)
  }
  const onTextChange = async text => {
    if (!urlFind.any.test(text)) return props.onTextChange(text)
    const [link] = text.match(urlFind.any)
    if (!link) return props.onTextChange(text)
    if (link !== text) return props.onTextChange(text)
    setShowLinkProgress(true)
    const linkData = await processLink(link)
    setShowLinkProgress(false)
    if (!linkData) return props.onInfo({
      message: 'Description not found at provided URL.',
      description: 'Unable to get description from the provided URL',
    })
    setLinkData(linkData)
    props.onTextChange(getDescription(linkData))
    if (getMedia(linkData)) props.onMediaChange([
        ...media, getMedia(linkData),
      ])
  }
  const onSend = (...args) => props.onSend(...args, linkData)
  return <>
    <ProgressBar visible={showLinkProgress} />
    {React.cloneElement(React.Children.only(props.children), {
      ...props, text, onTextChange, onSend,
    })}
  </>
}
