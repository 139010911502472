import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import PriceCard from './PriceCard'
import {AppContext} from '@web/hooks/context'

AgentInfo.propTypes = {
  agentId: PropTypes.string,
  productIndex: PropTypes.number,
};
function AgentInfo({agentId, productIndex}) {
  const [app] = useContext(AppContext)
  const productId = app.products?.productIds?.[productIndex]
  console.log('AgentInfo', agentId, productId)
  return <PriceCard productId={productId}/>
}

export default AgentInfo;