import React from 'react'
import {Button, Space, Typography} from 'antd'
import {ProgressBar} from './ProgressBar'
import {BsFillArrowUpCircleFill} from 'react-icons/bs'
import ContentActions from './ContentActions'
import {updateDocument, increment, Fields} from '@web/lib/firestore.db'

export default function BrainstormResponse({chatId, response = {}}) {
  const onClickRetry = (e) => {
    e.stopPropagation()
    updateDocument("ai-chat", chatId, {
      "prompt.retry": increment(1),
      "response.error": Fields.deleteField(),
    }).then(() => console.log("Retrying..."));
    return false
  }
  return (
    <ContentActions
      contentId={chatId}
      content={response}
      collection="ai-chat"
    >
      <li className={`brainstorm-message left ${(!!response.error || !response.answers) && 'error'}`}>
        {
          response.error ?
            <Space><BsFillArrowUpCircleFill />
              <Button size="small" onClick={onClickRetry}>Retry</Button>
            </Space>
            :
            !response.answers ?
              <ProgressBar
                visible float={false}
                color="rgba(256, 256, 256, 0.7)"
              />
              :
              response.answers.map((answer = {}, index) => (
                <Typography.Paragraph copyable={false} key={index}>
                  <p className="content-text"
                     dangerouslySetInnerHTML={{__html: answer.text?.trim()}}
                  />
                </Typography.Paragraph>

              ))
        }
      </li>
    </ContentActions>
  )
}
