import React from 'react'
import {Link} from 'wouter'
import {Typography} from 'antd'
import {ga} from '@web/lib/firebase'
import PropTypes from 'prop-types'

Logo.propTypes = {
  name: PropTypes.string.isRequired,
  showCode: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.node),
}

const codeStyle = {color: "green"}
function Logo({name, showCode, tags = []}) {
  return (
    <div className="space space-center-y">
      <Link to="/" className="logo-wrapper cursor-pointer" onClick={ga.click}>
        <Typography.Title level={4} className="cursor-pointer" title="App Logo">
          {name}
          {showCode && <code style={codeStyle}>{showCode}</code>}
        </Typography.Title>
      </Link>{tags}
    </div>
  )
}

export default Logo
