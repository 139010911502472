import React from 'react'
import {Empty, List} from 'antd'
import Post from './Post'

const articles = [
  [0, {
    title: '5 things you can do in 30 minutes to make your customers love you.',
    intro: 'The success of any business depends on its ability to build strong relationships with customers. But how can you make a lasting impression on your customers in limited time? The answer is simple – by taking just 30 minutes out of your day to focus on customer experience and satisfaction. Here are 5 things that you can do in less than 30 minutes to make your customers love your business.',
    list: [
      {
        text: 'Send a personalized thank you note - sending a personalized thank you note is a great way to show customers that you appreciate them and their business.',
        action: ['Draft with AI', '/business/ai_draft_customer_email', 'Write a personalized thank you note customer Chioma Eze who made a large purchase back in November.'],
      },
      {
        text: 'Offer a discount - offering customers a special discount or promotion can help encourage repeat purchases and show your appreciation for their loyalty.',
        action: ['Draft with AI', '/business/ai_draft_customer_email', 'Write an email offering a special discount to customers. Tell them that we appreciate their business and want to show our appreciation. The discount is good anytime this month'],
      },
      {
        text: 'Add a personal touch to customer service - going the extra mile by adding a personal touch to customer service shows customers that you care about them and their experience with your business.',
        action: ['Draft with AI', '/business/ai_draft_customer_email', 'Write an email inquiring customer Chioma Eze\'s trip went. Let he know how excited we are to hear about her adventures and hope that she enjoyed her new SONY Camera!'],
      },
      {
        text: 'Respond immediately to any customer inquiries received today - sometimes we fall behind and customer service takes a back seat as the backlog piles up. ' +
          '. Just thinking about the stack makes you feel like you\'ll never get to end. But who says you can\'t just jump to the end? Here\'s the reality, you are better off taking some time now and responding to the most recent customers. What\'s done is done. All that matters is what you do with the opportunities you have now. ' +
          'Take some time and respond now and start resetting expectations one customer at a time. And you\'ll be surprised how quickly you can get back on track.',
        action: ['Perfect Communication', '/business/ai-correct-grammar'],
      },
      {
        text: 'Ask for feedback - asking customers for feedback is a great way to get insights on how you can improve your products and services in less than 30 minutes.',
        action: ['Generate Questions', '/business/ai-customer-research', 'Write 3 questions to ask customers about their experience with our products and services.'],
      }
    ],
  }],
]

const renderArticle = ([articleId, article], index) => <div key={index}>
  <Post articleId={articleId} article={article} />
</div>
export default function Posts() {
  const pairs = articles
  if (!pairs.length) return (
    <Empty
      description="Educational content will show here."
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  )
  return <List
    renderItem={renderArticle}
    loading={false}
    bordered={false}
    dataSource={pairs}
  />
}